<template>
  <v-card flat elevation="3" min-height="500" class="rounded-10">
    <v-card-text>
      <router-view :key="$route.fullPath" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>